





























import Vue from 'vue'
import { DispatchingCredit } from '../../../../../../store/models/DispatchingCredit'

export default Vue.extend({
  data() {
    return {
      loading: false,
      columns: [
        {
          label: 'N° de dossier',
          field: 'reference'
        },
        {
          label: 'Nom',
          field: 'beneficiary.name',
          searchable: true
        },
        {
          label: 'Sexe',
          field: 'beneficiary.gender'
        },
        {
          label: 'Numéro',
          field: 'beneficiary.phone'
        },
        {
          label: 'Montant',
          field: 'amount',
          currency: true
        },
        {
          label: 'Agence Responsable',
          field: 'agency'
        },
        {
          label: 'Etat',
          field: 'state'
        }
      ]
    }
  },

  computed: {
    creditLineId(): string {
      return this.$route.params.creditLineId
    },

    dispatchingCredits(this: any) {
      return DispatchingCredit.query()
        .withAll()
        .where('creditLineId', this.creditLineId)
        .where('otm', this.$route.query.otm)
        .get()
    }
  },

  created() {
    this.loading = true
    DispatchingCredit.api()
      .fetchForOrganization({
        creditLineId: this.creditLineId,
        otm: this.$route.query.otm
      })
      .then(() => {
        this.loading = false
      })
      .catch((error: Error) => {
        console.error(error)
        this.loading = false
      })
  }
})
